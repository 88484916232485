<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">

				<el-input clearable size="small" placeholder="请输入内容" v-model="queryForm.keyword"
					style="width:300px;margin-right:20px">
					<el-select v-model="queryForm.ktype" slot="prepend" placeholder="请选择" style="width:100px">
						<el-option label="学生姓名" value="stu_name"></el-option>
						<el-option label="手机号" value="phone"></el-option>
						<el-option label="身份证号" value="id_number"></el-option>
						<el-option label="学校名称" value="school_name"></el-option>
						<el-option label="年级" value="grade_name"></el-option>
						<el-option label="班级" value="class_name"></el-option>
					</el-select>

				</el-input>

				<el-input clearable size="small" placeholder="请输入学校名称" v-model="queryForm.school"
					style="width:200px;margin-right:20px">
				</el-input>

				<el-select clearable v-model="queryForm.grade_name" placeholder="选择年级" size="small"
					style="width:120px;margin-right:20px">
					<el-option label="小班" value="小班"></el-option>
					<el-option label="中班" value="中班"></el-option>
					<el-option label="大班" value="大班"></el-option>
					<el-option label="一年级" value="一年级"></el-option>
					<el-option label="二年级" value="二年级"></el-option>
					<el-option label="三年级" value="三年级"></el-option>
					<el-option label="四年级" value="四年级"></el-option>
					<el-option label="五年级" value="五年级"></el-option>
					<el-option label="六年级" value="六年级"></el-option>
					<el-option label="七年级" value="七年级"></el-option>
					<el-option label="八年级" value="八年级"></el-option>
					<el-option label="九年级" value="九年级"></el-option>
					<el-option label="初一" value="初一"></el-option>
					<el-option label="初二" value="初二"></el-option>
					<el-option label="初三" value="初三"></el-option>
					<el-option label="高一" value="高一"></el-option>
					<el-option label="高二" value="高二"></el-option>
					<el-option label="高三" value="高三"></el-option>
				</el-select>
				<el-select clearable v-model="queryForm.class_name" placeholder="选择班级" size="small"
					style="width:120px;margin-right:20px">

					<el-option label="1班" value="1班"></el-option>
					<el-option label="2班" value="2班"></el-option>
					<el-option label="3班" value="3班"></el-option>
					<el-option label="4班" value="4班"></el-option>
					<el-option label="5班" value="5班"></el-option>
					<el-option label="6班" value="6班"></el-option>
					<el-option label="7班" value="7班"></el-option>
					<el-option label="8班" value="8班"></el-option>
					<el-option label="9班" value="9班"></el-option>
					<el-option label="10班" value="10班"></el-option>
					<el-option label="11班" value="11班"></el-option>
					<el-option label="12班" value="12班"></el-option>
					<el-option label="13班" value="13班"></el-option>
					<el-option label="14班" value="14班"></el-option>
					<el-option label="15班" value="15班"></el-option>
					<el-option label="16班" value="16班"></el-option>
					<el-option label="17班" value="17班"></el-option>
					<el-option label="18班" value="18班"></el-option>
					<el-option label="19班" value="19班"></el-option>
					<el-option label="20班" value="20班"></el-option>
				</el-select>

				<el-select clearable v-model="queryForm.status" placeholder="保单状态" size="small"
					style="width:120px;margin-right:20px">
					<el-option label="所有状态" :value="0"></el-option>
					<el-option label="保障中" value="1"></el-option>
					<el-option label="理赔审核中" value="2"></el-option>
					<el-option label="理赔完成" value="3"></el-option>
					<el-option label="理赔拒绝" value="4"></el-option>
					<el-option label="退款审核中" value="-1"></el-option>
					<el-option label="已退款" value="-2"></el-option>
					<el-option label="退款拒绝" value="-3"></el-option>

				</el-select>

				<el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>

				<el-button size="small" icon="el-icon-upload" @click="importShow = true">导入</el-button>

				<el-button size="small" type="text" icon="el-icon-download" @click="exportOrders">导出</el-button>
			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>保单管理</el-breadcrumb-item>
					<el-breadcrumb-item>已投保保单</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<el-table stripe :data="DataList" style="width: 100%;margin-bottom: 20px; color: #000;" border size="mini">
				<el-table-column type="index" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="stu_name" label="学生姓名" width="120">
					<template slot-scope="scope">
						<span style="color:palevioletred" v-if="scope.row.gender == 2">{{ scope.row.stu_name }}</span>
						<span style="color:skyblue" v-if="scope.row.gender == 1">{{ scope.row.stu_name }}</span>

					</template>
				</el-table-column>
				<el-table-column prop="id_number" label="学生身份证号" width="160">

				</el-table-column>
				<el-table-column prop="school_name" label="学校" min-width="120">

				</el-table-column>
				<el-table-column prop="grade_name" label="年级" width="80">

				</el-table-column>
				<el-table-column prop="class_name" label="班级" width="80">

				</el-table-column>
				<el-table-column prop="parent_name" label="家长姓名" width="120">

				</el-table-column>
				<el-table-column prop="phone" label="家长手机号" width="120">

				</el-table-column>
				<el-table-column prop="bx_name" label="保险名称" min-width="120">

				</el-table-column>
				<el-table-column prop="total_money" label="保险金额" width="120">

				</el-table-column>




				<el-table-column prop="start_time" label="保险有效期" min-width="280">
					<template slot-scope="scope">
						{{ scope.row.start_time }} 至 {{ scope.row.end_time }}

					</template>
				</el-table-column>
				<!-- <el-table-column prop="id" label="支付日期" width="180">
					<template slot-scope="scope">
						{{scope.row.pay_time}}

					</template>
				</el-table-column> -->

				<el-table-column prop="status" label="状态" width="120">
					<template slot-scope="scope">
						<div v-if="scope.row.time_status == 1">
							<div v-if="scope.row.status == 1"><span style="color:#1a50f5">保障中</span></div>
							<div v-if="scope.row.status == -1"><span style="color:orange">退款审核中</span></div>
							<div v-if="scope.row.status == -2"><span style="color:orangered">已退款</span></div>
							<div v-if="scope.row.status == -3"><span style="color:orangered">退款拒绝</span></div>
							<div v-if="scope.row.status == 2"><span style="color:orange">理赔审核中</span></div>
							<div v-if="scope.row.status == 3"><span style="color:orange">理赔拒绝</span></div>
							<div v-if="scope.row.status == 4"><span style="color:#1a50f5">理赔通过</span></div>
						</div>
						<div v-else><span style="color:#ccc">已过期</span></div>

					</template>
				</el-table-column>


				<el-table-column fixed="right" label="操作" width="80">
					<template slot-scope="scope">

						<el-button type="text" @click="viewInfo(scope.row)">查看</el-button>

					</template>
				</el-table-column>
			</el-table>

			<div style="height: 20px;">

			</div>


		</div>
		<div
			style="position: absolute; bottom: 0; height: 40px; width: 100%; text-align: right; background-color: #fff; z-index: 20;">
			<el-pagination @current-change="paginate" :current-page="Page.current_page" :page-size="Page.per_page"
				:total="Page.count" layout="total, prev, pager, next">
			</el-pagination>
		</div>



		<el-dialog title="导入保单" :visible.sync="importShow" width="800px" :close-on-click-modal="false"
			:destroy-on-close="true">
			<div style="text-align:center">
				<el-upload style="display: inline-block;" action="" :multiple="true" :http-request="uploadTemp"
					:on-success="handleXlsUpload" :show-file-list="false" name="image">
					<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">
						选择文件</el-button>



				</el-upload>
				<el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a href="/xls/保单导入模板.xlsx" style="text-decoration: none;color:inherit">下载导入模板</a>
				</el-button>
			</div>

		</el-dialog>





		<el-dialog title="保单详情" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1000px">
			<div style="padding-right: 40px;">
				<el-form v-if="EditItem" :model="EditItem" ref="EditItem" label-width="120px">

					<el-row style="padding-top: 20px;">
						<el-col :span="12">
							<el-form-item label="学生姓名">
								<el-input readonly v-model="EditItem.stu_name"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="性别">
								<el-input readonly :value="EditItem.gender == 1 ? '男' : '女'"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="身份证号">
								<el-input readonly v-model="EditItem.id_number"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="出生日期">
								<el-input readonly v-model="EditItem.birthday"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="所在学校">
								<el-input readonly v-model="EditItem.school_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="年级">
								<el-input readonly v-model="EditItem.grade_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="班级">
								<el-input readonly v-model="EditItem.class_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="家长姓名">
								<el-input readonly v-model="EditItem.parent_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="家长手机号">
								<el-input readonly v-model="EditItem.phone"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保险名称">
								<el-input readonly v-model="EditItem.bx_name"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="支付金额">
								<el-input readonly v-model="EditItem.total_money"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="支付时间">
								<el-input readonly v-model="EditItem.pay_time"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保险有效期">
								<el-input readonly :value="EditItem.start_time + ' 至 ' + EditItem.end_time"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保单号">
								<el-input readonly v-model="EditItem.pay_order_num"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="保单状态">
								<div v-if="EditItem.time_status == 1">
									<div v-if="EditItem.status == 1"><span style="color:#1a50f5">保障中</span></div>
									<div v-if="EditItem.status == -1"><span style="color:orange">退款审核中</span></div>
									<div v-if="EditItem.status == -2"><span style="color:orangered">已退款</span></div>
									<div v-if="EditItem.status == -3"><span style="color:orangered">退款拒绝</span></div>
									<div v-if="EditItem.status == 2"><span style="color:orange">理赔审核中</span></div>
									<div v-if="EditItem.status == 3"><span style="color:orange">理赔拒绝</span></div>
									<div v-if="EditItem.status == 4"><span style="color:#1a50f5">理赔通过</span></div>
								</div>
								<div v-else><span style="color:#ccc">已过期</span></div>
							</el-form-item>
						</el-col>

					</el-row>


				</el-form>
			</div>
			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button type="" @click="dialogVisible = false">关闭</el-button>
				</el-col>
			</el-row>
		</el-dialog>

	</div>
</template>

<script>

export default {
	components: {

	},
	data() {
		return {
			temphead: "../../../static/temphead.jpg",
			queryForm: {
				ktype: "stu_name",
				keyword: "",
				grade_name: "",
				class_name: "",
				school: "",
				status: ""

			},
			activeName: "first",
			DataList: [],
			dialogVisible: false,
			importShow: false,
			showTongji: false,
			Page: {
				current_page: 1,
				next_page: 2,
				pre_page: 20,
				per_page: 20,
				total_page: 0,
				count: 0
			},
			product_catalog: [],
			userinfo: {},
			datainfo: [],
			read_teacher: [],
			read_teacher_num: 0,
			read_no_teacher: [],
			read_no_teacher_num: 0,
			read_student: [],
			detailTitle: "",
			EditItem: null,
			//按钮权限
			auth: {
				edit: false,
				delete: false
			},
			TypeList: [],
			DeptData: [],
			showPhone: false,
			canAdd: false,
			canEdit: false

		}
	},

	mounted() {
		this.search()
	},
	methods: {


		paginate(val) {
			this.Page.page = val;
			this.query(val)
		},
		search: function () {
			this.query(1);
		},
		query(page) {
			this.queryForm.page = page
			this.$http.post('/api/baodan_list', this.queryForm).then(res => {
				for (let item of res.data.data) {
					try {


						item.stime = new Date(item.start_time.replace(/-/g, '/')).getTime()
						item.etime = new Date(item.end_time.replace(/-/g, '/')).getTime()
						item.ntime = new Date(item.ntime.replace(/-/g, '/')).getTime()

						item.start_time = item.start_time.split(' ')[0]
						item.end_time = item.end_time.split(' ')[0]
						//判断是否过期
						item.time_status = 0;
						if (item.ntime > item.etime) {
							item.time_status = -1
						} else if (item.ntime > item.stime && item.ntime < item.etime) {
							item.time_status = 1
						}
					} catch (error) {

					}
				}
				this.DataList = res.data.data;

				this.Page = res.data.page


			});
		},
		update(item) {

			sessionStorage.setItem("product", JSON.stringify(item))

			this.$router.push("/proedit?" + item.id)
		},
		add() {
			this.$router.push("/notifyedit")
		},
		deleteItem(item) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/notify_delete", {
					id: item.id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '删除成功'
					});

					this.query();
				})

			}).catch(() => {

			});
		},


		viewInfo(item) {

			this.EditItem = item
			this.dialogVisible = true
			this.getDetail(item)
		},
		getDetail(item){
			this.$http.post("/api/get_baodan_info",{id:item.id}).then(res=>{
				this.EditItem.id_number = res.data.id_number
			})
		},

		editItem(item) {
			this.$router.push("/notifyedit?id=" + item.id)
		},
		setTop(item) {
			this.$http.post("/api/article_top", {
				id: item.id,
				top: item.top ? 0 : 1
			}).then(res => {
				this.$message({
					type: 'success',
					message: '置顶成功'
				});

				this.query();
			})
		},

		closePhone() {
			this.showPhone = false
			this.showTongji = false
		},
		viewDetail(e) {
			this.EditItem = e
			this.showTongji = true
		},
		handleXlsUpload(e) {//教师导入

			this.$http.post("/api/baodan_import_students", { url: e.src }).then((res) => {
				this.$message.success('导入成功')
				this.importShow = false
				this.query();
			});
		},
		exportOrders() {
			this.$http.post('/api/baodan_list_export', this.queryForm).then(res => {
				if (res.data.url) {
					window.open(res.data.url, "_blank")
				}
			});
		}




	}
}
</script>
<style scoped type="text/less" lang="less">
.pageContainer {
	height: 100%;
}

.product-status {
	padding-bottom: 10px;
	border-bottom: 5px solid #e8e8e8;
}

.arttile {
	font-size: 18px;
	color: #333333;
	padding: 20px 0;
}

.typeitem {
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 0px 10px;
}

.contentcontent {
	padding: 20px 0;
}

.attaches {
	padding: 20px 0;
}

.attitem {
	color: #0785D1;
	cursor: pointer;
}

.useritem {
	width: 200px;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 5px;
	margin-right: 5px;
	border: 1px solid #f6f6f6;
	padding: 5px;
}

.useritem image {
	vertical-align: middle;
}
</style>
